import { getEnvironmentVariables } from 'src/data/constants/environment';

/**
 * This object will hold the environment variables.
 *
 * @private
 */
const APP_VAR: any = getEnvironmentVariables();

/**
 * Enum to define the appsync authentication types.
 *
 * @todo uncomment when appsync is setup
 * @private
 */
const AUTH_TYPE = {
  AMAZON_COGNITO_USER_POOLS: 'AMAZON_COGNITO_USER_POOLS'
};

/**
 * Value maps to RefreshTokenValidity defined in the PackOptUI/infrastructure CloudFormation template.
 * Any changes to the template should be reflected here. Note that the value represents number of days that
 * the refresh token is valid for.
 *
 * @type {Number}
 */
const REFRESH_TOKEN_VALIDITY = 1;

/**
 * Returns the full configuration object used to initialize AWS services (Cognito, AppSync, etc.).
 *
 * @enum {any}
 */
/*eslint-disable */
const SECURITY_CONFIG: any = {
  // Cognito
  aws_project_region: APP_VAR.COGNITO_REGION,
  aws_cognito_identity_pool_id: APP_VAR.COGNITO_IDENTITY_ID,
  aws_cognito_region: APP_VAR.COGNITO_REGION,
  aws_user_pools_id: APP_VAR.COGNITO_POOL_ID,
  aws_user_pools_web_client_id: APP_VAR.COGNITO_WEB_CLIENT_ID,
  oauth: {
    domain: APP_VAR.COGNITO_DOMAIN,
    scope: [
      'phone',
      'email',
      'openid',
      'profile',
      'aws.cognito.signin.user.admin'
    ],
    redirectSignIn: APP_VAR.COGNITO_REDIRECT_SIGNIN,
    redirectSignOut: APP_VAR.COGNITO_REDIRECT_SIGNOUT,
    responseType: 'code'
  },
  federationTarget: 'COGNITO_USER_POOLS',
  // Appsync
  aws_appsync_graphqlEndpoint: APP_VAR.APPSYNC_GRAPHQL_ENPOINT,
  aws_appsync_region: APP_VAR.APPSYNC_REGION,
  aws_appsync_authenticationType: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
  // Storage
  aws_user_files_s3_bucket: APP_VAR.S3_BUCKET,
  aws_user_files_s3_bucket_region: APP_VAR.S3_BUCKET_REGION
};
/*eslint-enable */

export { REFRESH_TOKEN_VALIDITY, SECURITY_CONFIG };
