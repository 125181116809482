import React, {
  FC,
  ReactElement,
  useEffect,
  useState
} from 'react';
import { Hub } from '@aws-amplify/core';
/** Global Store */
import store from 'src/data/redux/store';
import { setAccountAction } from 'src/data/redux/user/action-creators';
/** session */
import {
  getAuthenticatedUser,
  isSessionExpired,
  redirectToOriginator,
  redirectToLoginPage
} from 'src/data/modules/session/session';

/**
 * @author jonaoroz
 * @description Functional Component used as a auth guard for
 * the application. It checks if a user is logged in, if not
 * they are redirected to midway.
 *
 * Then listens for Hub events for signIn/signOut
 * @function
 * @returns {ReactElement | null} Either the root application or null
 * depending on if a user is logged in.
 */
const AuthenticationCheck: FC<{ children: ReactElement }> = ({ children }): ReactElement | null => {
  const [signedIn, setSignedIn] = useState<boolean>(false);

  useEffect(() => {
    // Check for token multiple times with a delay incase a sign in event happens
    let attempts = 0;

    const intervalId = setInterval(() => {
      getAuthenticatedUser().then((user: any) => {
        const isExpired = isSessionExpired(user);

        if (user && !isExpired) {
          setSignedIn(true);
          // dispatch action to set user
          store.dispatch(setAccountAction(user));
          clearInterval(intervalId);
        } else if (attempts > 3) {
          clearInterval(intervalId);
          redirectToLoginPage();
        }
        attempts += 1;
      });
    }, 400);

    Hub.listen('auth', ({ payload: { event } }) => {
      if (event === 'signIn') {
        redirectToOriginator();
        setSignedIn(true);
      } else if (event === 'signOut') {
        store.dispatch(setAccountAction(null));
        redirectToLoginPage();
      }
    });
  }, []);

  return signedIn ? React.cloneElement(children) : null;
};

export default AuthenticationCheck;
