import {
  applyMiddleware,
  combineReducers,
  createStore,
  Store
} from 'redux';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
/** User */
import { userReducer } from './user/reducer';

export interface IStoreState {
  user: any; // TODO: figure out better type inferance for this object
};

export const initialState: IStoreState = {
  user: null
};

/**
 * All combineReducers() does is generate a function that calls your reducers with the slices of state selected according to their keys,
 * and combining their results into a single object again. It's not magic.
 */
export const rootReducer = combineReducers({
  user: userReducer
});

export const store: Store<IStoreState, any> = createStore(
  rootReducer,
  initialState,
  composeWithDevTools(applyMiddleware(thunk))
);

export default store;
