/** redux */
import { ActionCreator } from 'redux';
import { ThunkAction } from 'redux-thunk';
/** amplify */
import { Auth } from '@aws-amplify/auth';
/** actions */
import { ISetAccountAction } from './actions';

export const setAccountAction = (user: any): ISetAccountAction => ({
  type: 'SetAccount',
  user: user
});

export const getAuthenticatedUser: ActionCreator<ThunkAction<
  // The type of the last action to be dispatched - will always be promise<T> for async actions
  Promise<ISetAccountAction>,
  // The type for the data within the last action
  any,
  // The type of the parameter for the nested function
  unknown,
  // The type of the last action to be dispatched
  ISetAccountAction
>> = () => async (dispatch: any) => {
  try {
    const user: any = await Auth.currentAuthenticatedUser();
    return dispatch(setAccountAction(user));
  } catch(error) {
    return dispatch(setAccountAction(null));
  }
};
