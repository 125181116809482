import React, {
  FC,
  lazy,
  LazyExoticComponent,
  Suspense
} from 'react';
import ReactDOM from 'react-dom';
import { HashRouter as Router } from "react-router-dom";
import { Provider } from 'react-redux';
import { Amplify } from 'aws-amplify';
/** service worker */
import * as serviceWorker from './serviceWorker';
/** Global Store */
import store from './data/redux/store';
/** data/constants */
import { SECURITY_CONFIG } from './data/constants/security-config';
/** data/providers */
import AppLayoutProvider from 'src/data/providers/AppLayoutProvider';
/** components */
import AuthenticationCheck from 'src/components/layout/AuthenticationCheck';
/** css */
import './index.css';

Amplify.configure(SECURITY_CONFIG);

const App: LazyExoticComponent<FC> = lazy(() => import(/* webpackChunkName: 'App' */'./App'));

ReactDOM.render(
  <Provider store={store}>
    <AuthenticationCheck>
      <Router hashType="noslash">
        <Suspense fallback="">
          <AppLayoutProvider>
            <App />
          </AppLayoutProvider>
        </Suspense>
      </Router>
    </AuthenticationCheck>
  </Provider>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
