import { Auth } from '@aws-amplify/auth';
import { Hub } from '@aws-amplify/core';
import { SECURITY_CONFIG } from 'src/data/constants/security-config'

export type SessionHandlers = {
  onSessionExpired: () => void;
  onSessionWillExpire: (isExpiring: boolean) => void;
};

const {
  aws_user_pools_web_client_id: clientId,
  oauth: {
    domain,
    scope,
    redirectSignIn,
    responseType
  }
} = SECURITY_CONFIG;

const AUTHORIZE_URI = `https://${domain}/authorize?client_id=${clientId}&response_type=${responseType}&scope=${scope.join('+')}&redirect_uri=${redirectSignIn}`;

const REFRESH_TOKEN_VALIDITY = 1;
/**
 * @module
 * data/modules/session
 */
// The following keys are used for setting retrieving values in local/sessionStorage
const REDIRECT_URI_KEY = 'redirect_uri';

// These are used for dispatching/listening to custom Hub events.
const SESSION_EXPIRED = 'session_expired';
const SESSION_WILL_EXPIRE = 'session_will_expire';
const SESSION_TIMER_INTERVAL = 30000;

/**
 * Redirects to the Cognito login page with the appropriate redirect URL. Note that the REDIRECT_URI has to
 * match one of the callback URL's defined in the Cognito console otherwise it will show an error page. For
 * that reason we store the current location in sessionStorage so that we can actually redirect to the correct
 * landing page after the Cognito redirect into our application.
 */
const redirectToLoginPage = (): void => {
  sessionStorage.setItem(REDIRECT_URI_KEY, window.location.href);
  window.location.assign(AUTHORIZE_URI);
};

/**
 * Sets keys in sessionStorage and redirects user if the redirect URI is different than the current location.
 *
 * @note Exposed for testing only
 * @private
 * @returns {void}
 */
const redirectToOriginator = (): void => {
  const redirectUri: string = sessionStorage.getItem(REDIRECT_URI_KEY) || window.location.href;

  sessionStorage.removeItem(REDIRECT_URI_KEY);

  // ignore the next line in code coverage
  // its not possible to mocke the return value
  // of window.location.href
  /* istanbul ignore if  */
  if (redirectUri !== window.location.href) {
    window.location.assign(encodeURI(redirectUri));
  }
};

/**
 * Starts the session timer and dispatches events when session expiration
 * is approaching, as well as when session HAS expired. To determine when
 * the session will expire we use the "auth_time" + 24 hours which is when
 * the refresh token will expire (1 day).
 *
 * @note Exposed for testing only
 * @async
 * @private
 */
const startSessionTimer = async (): Promise<void> => {
  // Values are all in seconds.
  const fiveMinutes = 60 * 5;
  const oneDay = 86400;
  const refreshTokenValidity = REFRESH_TOKEN_VALIDITY * oneDay;

  // Will be used to suppress further SESSION_WILL_EXPIRE events after initial event is dispatched.
  let sessionWillExpireNotice = false;

  try {
    const accessToken = (await Auth.currentSession()).getAccessToken();
    const expiresTimestamp = accessToken.payload.auth_time + refreshTokenValidity;

    setInterval(() => {
      const currentTimestamp = Math.trunc(new Date().getTime() / 1000);
      const timeRemaining = expiresTimestamp - currentTimestamp;

      if (!sessionWillExpireNotice && timeRemaining < fiveMinutes) {
        sessionWillExpireNotice = true;
        Hub.dispatch(SESSION_WILL_EXPIRE, {
          event: `[${SESSION_WILL_EXPIRE}]: Session is going to expire soon.`
        });
      } else if (timeRemaining <= 0) {
        Hub.dispatch(SESSION_EXPIRED, {
          event: `[${SESSION_EXPIRED}]: Session has expired. Please sign in again.`
        });
      }
    }, SESSION_TIMER_INTERVAL);
  } catch (e) {
    Hub.dispatch(SESSION_EXPIRED, {
      event: `[${SESSION_EXPIRED}]: Session has expired. Please sign in again.`
    });
  }
}

/**
 * @description Determine if a user' session has expired
 * @param {any} user - user returned by getAuthenticatedUser()
 * @returns {boolean} true if user session has expired, false otherwise
 */
const isSessionExpired = (user: any): boolean => {
  if (!user) return true;

  const oneDay = 86400;
  const refreshTokenValidity = REFRESH_TOKEN_VALIDITY * oneDay;

  const expiresTimestamp = user.signInUserSession.accessToken.payload.auth_time + refreshTokenValidity;

  const currentTimestamp = Math.trunc(new Date().getTime() / 1000);
  const timeRemaining = expiresTimestamp - currentTimestamp;

  return timeRemaining <= 0;
};

/**
 * Retrieves and returns the jwtToken for the currently authenticated user.
 *
 * @async
 * @returns {Promise<String>} accessToken
 * Cognito jwtToken of currently authenticated user.
 */
const getAccessToken = async(): Promise<string> => {
  let accessToken = '';

  try {
    await Auth.currentAuthenticatedUser().then((user) => {
      accessToken = user.signInUserSession.idToken.jwtToken;
    });
  } catch (ex) {
    // This had to be added because Amplify doesn't catch the exception, this means
    // that the user is not logged in, there is other code that redirects to login page.
  }

  return accessToken;
};

/**
 * @author jonaoroz
 * @async
 * @description Checks if there is an authenticated user logged in.
 *
 * @returns {Promise<any>} CognitoUser or null
 */
const getAuthenticatedUser = async(): Promise<any> => {
  let user: any = null;

  try {
    user = await Auth.currentAuthenticatedUser();
  } catch (ex) {
    // This had to be added because Amplify doesn't catch the exception, this means
    // that the user is not logged in, there is other code that redirects to login page.
  }

  return user;
};

/**
 * Determines if access tokens exist (jwtToken) so that the session can be initiated.
 *
 * @async
 * @returns {Promise<Boolean>} hasAccessTokens
 * Returns true if current user has access tokens already; false otherwise.
 */
const hasAccessTokens = async(): Promise<boolean> => {
  try {
    await Auth.currentAuthenticatedUser();
  } catch (e) {
    return false;
  }

  return true;
};
/**
 * Subscribes to auth messages to handle sign in and session expiration. Also starts the session timer.
 *
 * @param {Object} sessionHandlers
 *
 * @param {Function} [sessionHandlers.onSessionExpired]
 * Invoked when session expires to handle any necessary clean up and/or user messaging.
 *
 * @param {Function} [sessionHandlers.onSessionWillExpire]
 * Invoked 5 minutes before session is set to expire to inform user that they should save any changes and reauthenticate.
 */
const initializeSession = ({
  onSessionExpired,
  onSessionWillExpire,
}: SessionHandlers): void => {

  Hub.listen(SESSION_EXPIRED, onSessionExpired);
  Hub.listen(SESSION_WILL_EXPIRE, () => onSessionWillExpire(true));

  setTimeout(startSessionTimer, SESSION_TIMER_INTERVAL);
};



/**
 * Signs the user out and redirects back to the Cognito sign in page.
 *
 * @async
 * @returns {Promise<void>}
 */
const signOut = async(): Promise<void> => {
  await Auth.signOut();
};

export {
  AUTHORIZE_URI,
  SESSION_EXPIRED,
  SESSION_WILL_EXPIRE,
  SESSION_TIMER_INTERVAL,
  getAccessToken,
  getAuthenticatedUser,
  hasAccessTokens,
  initializeSession,
  isSessionExpired,
  redirectToLoginPage,
  redirectToOriginator,
  signOut,
  startSessionTimer
};
