import { parse, ParseResult } from "papaparse";
import { Flashbar } from '@amzn/awsui-components-react';
import { FlashbarProps } from '@amzn/awsui-components-react-v3';

/**
 * @description Copy a given text to user clipboard
 * @param {string} text string to copy
 * @returns {Promise<void> | undefined}
 */

declare global {
  // eslint-disable-next-line
  interface Navigator {
    msSaveBlob: (blobOrBase64: Blob | string, filename: string) => void;
  }
}

export const copyToClipboard = (text: string): Promise<void> | undefined => {
  try {
    // Try to use the modern clipboard API.
    // Some browsers only allow this API in response to a user initiated event.
    return navigator.clipboard.writeText(text);
  } catch (error) {
    // Fall back to using a textarea.
    // https://stackoverflow.com/a/30810322/898577
    const textArea = document.createElement("textarea");
    textArea.value = text;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
    } finally {
      document.body.removeChild(textArea);
    }
  }
};

/**
 * @description Download the given csv data using native browser
 * capabilities.
 * @param {string} fileName - name to give csv
 * @param {string} csv - data to download
 */
export const downloadCsv = (fileName: string, csv: string): void => {
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  try {
    navigator.msSaveBlob(blob, fileName);
  } catch (error) {
    const link = document.createElement("a");

    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", fileName);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

/**
 * Deep copy function for TypeScript.
 * @param T Generic type of target/copied value.
 * @param target Target value to be copied.
 * @see Source project, ts-deepcopy https://github.com/ykdr2017/ts-deepcopy
 * @see Code pen https://codepen.io/erikvullings/pen/ejyBYg
 */
export const deepCopy = <T>(target: T): T => {
  if (target === null) {
    return target;
  }
  if (target instanceof Date) {
    return new Date(target.getTime()) as any;
  }
  if (target instanceof Array) {
    const cp = [] as any[];
    (target as any[]).forEach((v) => { cp.push(v); });
    return cp.map((n: any) => deepCopy<any>(n)) as any;
  }
  if (typeof target === 'object') {
    const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
    Object.keys(cp).forEach(k => {
      cp[k] = deepCopy<any>(cp[k]);
    });
    return cp as T;
  }
  return target;
};

/** @export
 * @description Given a File, parse it using PapaParse
 * @param {File} file
 * @returns {Promise<any>}
 */
export const parseCsvFile = (file: File): Promise<ParseResult<any>> => {
  return new Promise((complete, error) => {
    parse(file, { complete, error, dynamicTyping: true });
  });
};

/**
 * @description Given a list and a list of indices, return the list without the indices
 * @param {any[]} list
 * @param {number[]} indices
 * @return {any[]}
 */
export const removeByIndices = (list: any[], indices: number[]): any[] => {
  return list.filter((_, index: number) => indices.indexOf(index) === -1);
}

/**
 * Randomly shuffle an array
 * @param  {any[]} array The array to shuffle
 * @returns {any[]}
 */
export const shuffle = (array: any[]) => array.map((a) => [Math.random(), a]).sort((a, b) => a[0] - b[0]).map((a) => a[1]);

/**
 * Shuffle an array with deterministic randomness provided by the seed
 * Source: https://github.com/yixizhang/seed-shuffle
 * @param {any[]} array
 * @param {number} seed
 */
export const deterministicShuffle = (originalArray: any[], userSeed?: number) => {
  const array = [...originalArray];
  let currentIndex = array.length, temporaryValue, randomIndex;
  let seed = userSeed || 1;
  const random = function () {
    const x = Math.sin(seed++) * 10000;
    return x - Math.floor(x);
  };
  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(random() * currentIndex);
    currentIndex -= 1;
    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }
  return array;
};

/**
 * @description Adds a new message to the message list
 * @param setMessages useState function to set messages
 * @param {Flashbar.MessageDefinition} newMessage New message to add
 */
export const addNewMessageV3 = (
  setMessages: React.Dispatch<React.SetStateAction<FlashbarProps.MessageDefinition[]>>,
  message: FlashbarProps.MessageDefinition
): void => {
  const label = Math.random().toString();
  setMessages((messages: FlashbarProps.MessageDefinition[]) => [
    ...messages,
    {
      ...message,
      dismissLabel: label,
      onDismiss: () => setMessages((messages: FlashbarProps.MessageDefinition[]) => {
        return messages.filter(({ dismissLabel }: FlashbarProps.MessageDefinition) => dismissLabel !== label);
      })
    }
  ]);
};

/**
 * @description Adds a new message to the message list
 * @param setMessages useState function to set messages
 * @param {Flashbar.MessageDefinition} newMessage New message to add
 */
export const addNewMessage = (
  setMessages: React.Dispatch<React.SetStateAction<Flashbar.MessageDefinition[]>>,
  message: Flashbar.MessageDefinition
): void => {
  const dismissLabel = Math.random().toString();
  setMessages((messages: any) => [
    ...messages,
    {
      ...message,
      dismissLabel,
      dismiss: () =>
        setMessages((messages: any) =>
          messages.filter(
            (message: Flashbar.MessageDefinition) => message.dismissLabel !== dismissLabel
          )
        ),
    },
  ]);
}

/**
 * @description Format a string to be used as a URL.
 * First, swaps blank spaces and '_' for '-'
 * Then, removes everything that's not a word, digit, or '-'
 * @param {string} param String to format
 * @returns {string}
 */
export const makeUrlAble = (param: string): string => {
  return param.trim().replace(/[_\s]/g, "-").replace(/[^\w\d-]/g, "");
};

/**
 * @description Rounds the value to the closest step based on the passed roundFunc
 * @param value
 * @param step
 */
export const roundValue = (value: number, step: number, roundFunc: (num: number) => number = Math.round): number => {
  const inv = 1.0 / step;
  return roundFunc(value * inv) / inv;
}
/**
 * @description Rounds value to the closest step
 */
export const round = (value: number, step = 0.5): number => roundValue(value, step, Math.round);
/**
 * @description Applies ceil to the next `step`
 */
export const ceil = (value: number, step = 0.5): number => roundValue(value, step, Math.ceil);
/**
 * @description Applies ceil to the next `step`
 */
export const floor = (value: number, step = 0.5): number => roundValue(value, step, Math.floor);

/* @description Get a random Integer between min and max
 * @param {number} max
 * @param {number} min
 */
export const getRandomInt = (max: number, min = 0) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

/** @description Rounds value withing bounds */
export const roundWithinBounds = (value: number, step: number, min: number, max: number, roundFunc: (num: number) => number = Math.round): number => {
  const roundedValue = roundValue(value, step, roundFunc);
  if (roundedValue <= min) return min;
  if (roundedValue >= max) return max;
  return roundedValue;
};

/**
 * @description Rounds a value within rounded bounds
 * @param {number} value Value to round
 * @param {number} step Step to use when rounding
 * @param {number} min Lower bound. Doesn't have to be rounded
 * @param {number} max Upper bound. Doesn't have to be rounded
 * @param {(x: number) => number} roundFunc Function to use for rounding. Default is `Math.round`
 */
export const roundWithinRoundedBounds = (value: number, step: number, min: number, max: number, roundFunc: (num: number) => number = Math.round): number => {
  const roundedMin = ceil(min, step);
  const roundedMax = floor(max, step);
  const roundedValue = roundValue(value, step, roundFunc);

  if (roundedValue <= roundedMin) return roundedMin;
  if (roundedValue >= roundedMax) return roundedMax;
  return roundedValue;
};

export const getRenderableBool = (value: boolean | null | undefined): string => {
  if (value === true) return 'True';
  if (value === false) return 'False';
  return '-';
};

export const isListElementInListOfLists = (
  list: any[][],
  elem: any[]
): boolean =>
  list.some(
    (currElem: any[]) =>
      currElem[0] === elem[0] &&
      currElem[1] === elem[1] &&
      currElem[2] === elem[2]
  );

/**
 * @description
 * Given a string it will convert it into snake case, e.g.
 * variableName -> variable_name. There is also the option
 * of having an all capital version.
 * @param {string} str
 * @param {boolean} capitalize
 * @returns {string} str converted to snake caseing
 */
export const toSnakeCase = (str: string, capitalize = true) => {
  const matched = str.match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g);

  if (!matched) return '';

  return matched.map((x: string) => capitalize
    ? x.toUpperCase()
    : x.toLocaleLowerCase()
  ).join('_');
};
