import { Reducer } from 'redux';
import { UserActions } from "./actions";

/**
 * @param {any} [state=null]
 * @param {UserAction} action
 * @returns {any}
 */
export const userReducer: Reducer<any, UserActions> = (state: any = null, action: UserActions): any => {
  switch (action.type) {
    case 'SetAccount':
      return action.user ;
    default:
      return state;
  }
};
