const COGNITO_REGION = 'us-west-2';

// PackTools - ALPHA
const COGNITO_POOL_ID_ALPHA_V2 = 'us-west-2_fZrXWvuu9';
const COGNITO_WEB_CLIENT_ID_ALPHA_V2 = '22i4rqtf3ecdo5d5mticspc6pm';
const COGNITO_IDENTITY_ID_ALPHA_V2 =
  'us-west-2:2f9914a9-8951-44a7-b5f0-866b8dc68b80';
const COGNITO_DOMAIN_ALPHA_V2 =
  'alpha-packtools.auth.us-west-2.amazoncognito.com';

const APPSYNC_API_URL_ALPHA_V2 = 'il3a3qsxfnbbznjjhktkpwqxsq';
const SHARED_STORAGE_BUCKET_ALPHA_V2 = 'packtools-storage-alpha-570388465206';

// PackTools - GAMMA
const COGNITO_POOL_ID_GAMMA_V2 = 'us-west-2_DhbVnN5wv';
const COGNITO_WEB_CLIENT_ID_GAMMA_V2 = '4q9ncs9m6fv97hod802o8k0s7u';
const COGNITO_IDENTITY_ID_GAMMA_V2 =
  'us-west-2:26f71fce-d646-483b-93ff-2c1d0c155e22';
const COGNITO_DOMAIN_GAMMA_V2 =
  'gamma-packtools.auth.us-west-2.amazoncognito.com';

const APPSYNC_API_URL_GAMMA_V2 = 'enz7tlsx5bgjlbzwg3rqt7ywhy';
const SHARED_STORAGE_BUCKET_GAMMA_V2 = 'packtools-storage-gamma-570388465206';

// PackTools - PROD
const COGNITO_POOL_ID_PROD_V2 = 'us-west-2_2Lz1Jwl6b';
const COGNITO_WEB_CLIENT_ID_PROD_V2 = 'rpsvaui7a64d1odikedsr6fiq';
const COGNITO_IDENTITY_ID_PROD_V2 =
  'us-west-2:02e88131-4242-4c9c-9b7e-5613ad08e8aa';
const COGNITO_DOMAIN_PROD_V2 = 'packtools.auth.us-west-2.amazoncognito.com';

const APPSYNC_API_URL_PROD_V2 = 'lc2pz6oomvf6pdp3whrx5li2au';
const SHARED_STORAGE_BUCKET_PROD_V2 = 'packtools-storage-prod-568365243352';

// PackOpt-ALPHA
const COGNITO_POOL_ID_ALPHA = 'us-west-2_w48RevFkI';
const COGNITO_WEB_CLIENT_ID_ALPHA = '4vjsgrvfjcn3rvhjgca5s7df3j';
const COGNITO_IDENTITY_ID_ALPHA =
  'us-west-2:1e453305-7d06-4abd-9384-c14acef39fc0';
const COGNITO_DOMAIN_ALPHA = 'alpha-packoptui.auth.us-west-2.amazoncognito.com';

const APPSYNC_API_URL_ALPHA = 'ach2kqudufg6vk5fc3yayxc2n4';
const SHARED_STORAGE_BUCKET_ALPHA = 'packoptui-storage-alpha';

// PackOpt-GAMMA
const COGNITO_POOL_ID_GAMMA = 'us-west-2_YnvBPsifu';
const COGNITO_WEB_CLIENT_ID_GAMMA = '1dav5a828tg9mk08p133kpndmi';
const COGNITO_IDENTITY_ID_GAMMA =
  'us-west-2:62a24b94-ee23-45e6-b135-65d7864cd2fe';
const COGNITO_DOMAIN_GAMMA = 'gamma-packoptui.auth.us-west-2.amazoncognito.com';

const APPSYNC_API_URL_GAMMA = 'd3hdriehwbdmfm4fxyb3l67sci';
const SHARED_STORAGE_BUCKET_GAMMA = 'packoptui-storage-gamma';

/**
 * @module
 * common/modules/environment
 */

/**
 * Enum to define various environments.
 *
 * @todo finalize mappings once all sub-domains are properly set up for the various stages/regions
 *
 * @prop {String} ALPHA 'alpha.packopt.cpex.amazon.dev'
 * @prop {String} PROD_NA ''
 */
const ENVIRONMENT = {
  ALPHA: 'alpha.packopt.cpex.amazon.dev',
  ALPHA_V2: 'alpha.packtools.cpex.amazon.dev',
  GAMMA_V2: 'gamma.packtools.cpex.amazon.dev',
  PROD_V2: 'packtools.cpex.amazon.dev',
  GAMMA: 'gamma.packopt.cpex.amazon.dev',
  PROD_NA: '',
};

/**
 * Object which contains environment-specific AWS configurations.
 *
 * @prop {Object} LOCAL
 * @prop {Object} ALPHA
 * @prop {Object} PROD_NA
 */
const ENVIRONMENT_CONFIG = {
  LOCAL: {
    COGNITO_REGION,
    COGNITO_POOL_ID: COGNITO_POOL_ID_ALPHA_V2,
    COGNITO_WEB_CLIENT_ID: COGNITO_WEB_CLIENT_ID_ALPHA_V2,
    COGNITO_IDENTITY_ID: COGNITO_IDENTITY_ID_ALPHA_V2,
    COGNITO_DOMAIN: COGNITO_DOMAIN_ALPHA_V2,
    COGNITO_REDIRECT_SIGNIN: 'http://localhost:3000/',
    COGNITO_REDIRECT_SIGNOUT: 'http://localhost:3000/',
    APPSYNC_REGION: 'us-west-2',
    APPSYNC_GRAPHQL_ENPOINT: `https://${APPSYNC_API_URL_ALPHA_V2}.appsync-api.us-west-2.amazonaws.com/graphql`,
    AMPLIFY_LOG_LEVEL: 'DEBUG',
    S3_BUCKET: SHARED_STORAGE_BUCKET_ALPHA_V2,
    S3_BUCKET_REGION: 'us-west-2',
  },
  ALPHA: {
    COGNITO_REGION,
    COGNITO_POOL_ID: COGNITO_POOL_ID_ALPHA,
    COGNITO_WEB_CLIENT_ID: COGNITO_WEB_CLIENT_ID_ALPHA,
    COGNITO_IDENTITY_ID: COGNITO_IDENTITY_ID_ALPHA,
    COGNITO_DOMAIN: COGNITO_DOMAIN_ALPHA,
    COGNITO_REDIRECT_SIGNIN: `https://${ENVIRONMENT.ALPHA}/`,
    COGNITO_REDIRECT_SIGNOUT: `https://${ENVIRONMENT.ALPHA}/`,
    APPSYNC_REGION: 'us-west-2',
    APPSYNC_GRAPHQL_ENPOINT: `https://${APPSYNC_API_URL_ALPHA}.appsync-api.us-west-2.amazonaws.com/graphql`,
    AMPLIFY_LOG_LEVEL: 'DEBUG',
    S3_BUCKET: SHARED_STORAGE_BUCKET_ALPHA,
    S3_BUCKET_REGION: 'us-west-2',
  },
  GAMMA: {
    COGNITO_REGION,
    COGNITO_POOL_ID: COGNITO_POOL_ID_GAMMA,
    COGNITO_WEB_CLIENT_ID: COGNITO_WEB_CLIENT_ID_GAMMA,
    COGNITO_IDENTITY_ID: COGNITO_IDENTITY_ID_GAMMA,
    COGNITO_DOMAIN: COGNITO_DOMAIN_GAMMA,
    COGNITO_REDIRECT_SIGNIN: `https://${ENVIRONMENT.GAMMA}/`,
    COGNITO_REDIRECT_SIGNOUT: `https://${ENVIRONMENT.GAMMA}/`,
    APPSYNC_REGION: 'us-west-2',
    APPSYNC_GRAPHQL_ENPOINT: `https://${APPSYNC_API_URL_GAMMA}.appsync-api.us-west-2.amazonaws.com/graphql`,
    AMPLIFY_LOG_LEVEL: 'DEBUG',
    S3_BUCKET: SHARED_STORAGE_BUCKET_GAMMA,
    S3_BUCKET_REGION: 'us-west-2',
  },
  ALPHA_V2: {
    COGNITO_REGION,
    COGNITO_POOL_ID: COGNITO_POOL_ID_ALPHA_V2,
    COGNITO_WEB_CLIENT_ID: COGNITO_WEB_CLIENT_ID_ALPHA_V2,
    COGNITO_IDENTITY_ID: COGNITO_IDENTITY_ID_ALPHA_V2,
    COGNITO_DOMAIN: COGNITO_DOMAIN_ALPHA_V2,
    COGNITO_REDIRECT_SIGNIN: `https://${ENVIRONMENT.ALPHA_V2}/`,
    COGNITO_REDIRECT_SIGNOUT: `https://${ENVIRONMENT.ALPHA_V2}/`,
    APPSYNC_REGION: 'us-west-2',
    APPSYNC_GRAPHQL_ENPOINT: `https://${APPSYNC_API_URL_ALPHA_V2}.appsync-api.us-west-2.amazonaws.com/graphql`,
    AMPLIFY_LOG_LEVEL: 'DEBUG',
    S3_BUCKET: SHARED_STORAGE_BUCKET_ALPHA_V2,
    S3_BUCKET_REGION: 'us-west-2',
  },
  GAMMA_V2: {
    COGNITO_REGION,
    COGNITO_POOL_ID: COGNITO_POOL_ID_GAMMA_V2,
    COGNITO_WEB_CLIENT_ID: COGNITO_WEB_CLIENT_ID_GAMMA_V2,
    COGNITO_IDENTITY_ID: COGNITO_IDENTITY_ID_GAMMA_V2,
    COGNITO_DOMAIN: COGNITO_DOMAIN_GAMMA_V2,
    COGNITO_REDIRECT_SIGNIN: `https://${ENVIRONMENT.GAMMA_V2}/`,
    COGNITO_REDIRECT_SIGNOUT: `https://${ENVIRONMENT.GAMMA_V2}/`,
    APPSYNC_REGION: 'us-west-2',
    APPSYNC_GRAPHQL_ENPOINT: `https://${APPSYNC_API_URL_GAMMA_V2}.appsync-api.us-west-2.amazonaws.com/graphql`,
    AMPLIFY_LOG_LEVEL: 'DEBUG',
    S3_BUCKET: SHARED_STORAGE_BUCKET_GAMMA_V2,
    S3_BUCKET_REGION: 'us-west-2',
  },
  PROD_V2: {
    COGNITO_REGION,
    COGNITO_POOL_ID: COGNITO_POOL_ID_PROD_V2,
    COGNITO_WEB_CLIENT_ID: COGNITO_WEB_CLIENT_ID_PROD_V2,
    COGNITO_IDENTITY_ID: COGNITO_IDENTITY_ID_PROD_V2,
    COGNITO_DOMAIN: COGNITO_DOMAIN_PROD_V2,
    COGNITO_REDIRECT_SIGNIN: `https://${ENVIRONMENT.PROD_V2}/`,
    COGNITO_REDIRECT_SIGNOUT: `https://${ENVIRONMENT.PROD_V2}/`,
    APPSYNC_REGION: 'us-west-2',
    APPSYNC_GRAPHQL_ENPOINT: `https://${APPSYNC_API_URL_PROD_V2}.appsync-api.us-west-2.amazonaws.com/graphql`,
    AMPLIFY_LOG_LEVEL: 'DEBUG',
    S3_BUCKET: SHARED_STORAGE_BUCKET_PROD_V2,
    S3_BUCKET_REGION: 'us-west-2',
  },
};

export enum COGNITO_GROUPS {
  ADMIN = 'Admin',
  AMAZON_JP = 'AmazonJP',
  DEFAULT = 'Amazon',
  FAAS_ADMIN_ALPHA = 'CPEX-FaaS-Admin-User-Alpha',
  FAAS_ADMIN_GAMMA = 'CPEX-FaaS-Admin-User-Gamma',
  FAAS_ADMIN_PROD = 'CPEX-FaaS-Admin-User-Prod',
}

/**
 * Returns object with the configuration properties that correspond to the current environment.
 *
 * @returns {any} ENVIRONMENT_CONFIG[ENVIRONMENT]
 */
const getEnvironmentVariables = (): any => {
  const { hostname } = window.location;

  switch (hostname) {
    case ENVIRONMENT.ALPHA:
      return ENVIRONMENT_CONFIG.ALPHA;
    case ENVIRONMENT.GAMMA:
      return ENVIRONMENT_CONFIG.GAMMA;
    case ENVIRONMENT.ALPHA_V2:
      return ENVIRONMENT_CONFIG.ALPHA_V2;
    case ENVIRONMENT.GAMMA_V2:
      return ENVIRONMENT_CONFIG.GAMMA_V2;
    case ENVIRONMENT.PROD_V2:
      return ENVIRONMENT_CONFIG.PROD_V2;
    default:
      return ENVIRONMENT_CONFIG.LOCAL;
  }
};

/**
 * Tests if request is coming from the local/dev environment.
 *
 * @returns {Boolean} isLocalhost Returns true if on local dev environment; false otherwise.
 */
const isLocalhost = (): boolean => {
  const { hostname } = window.location;

  return Boolean(
    hostname === 'localhost' ||
      // This will be the hostname if accessing from a VirtualBox VM
      hostname === '10.0.2.2' ||
      // [::1] is the IPv6 localhost address.
      hostname === '[::1]' ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
  );
};

enum EnvironmentName {
  Alpha = 'alpha',
  Gamma = 'gamma',
  Prod = 'prod'
}

// a temporary check so we can distinguish
// between the old packopt.cpex.amazon.dev environments
// and the new packtools.cpex.amazon.dev environments
const getEnv = (): {
  isPackToolsEnv: boolean;
  envName: EnvironmentName;
  storageBucketName: string;
} => {
  // from now on, localhost will be considered packtools, too
  if (isLocalhost()) {
    return {
      isPackToolsEnv: true,
      envName: EnvironmentName.Alpha,
      storageBucketName: SHARED_STORAGE_BUCKET_ALPHA_V2,
    };
  }

  const { hostname } = window.location;

  if (hostname === 'alpha.packopt.cpex.amazon.dev') {
    return {
      isPackToolsEnv: false,
      envName: EnvironmentName.Alpha,
      storageBucketName: SHARED_STORAGE_BUCKET_ALPHA,
    };
  }
  if (hostname === 'gamma.packopt.cpex.amazon.dev') {
    return {
      isPackToolsEnv: false,
      envName: EnvironmentName.Gamma,
      storageBucketName: SHARED_STORAGE_BUCKET_GAMMA,
    };
  }
  if (hostname === 'alpha.packtools.cpex.amazon.dev') {
    return {
      isPackToolsEnv: true,
      envName: EnvironmentName.Alpha,
      storageBucketName: SHARED_STORAGE_BUCKET_ALPHA_V2,
    };
  }
  if (hostname === 'gamma.packtools.cpex.amazon.dev') {
    return {
      isPackToolsEnv: true,
      envName: EnvironmentName.Gamma,
      storageBucketName: SHARED_STORAGE_BUCKET_GAMMA_V2,
    };
  }
  if (hostname === 'packtools.cpex.amazon.dev') {
    return {
      isPackToolsEnv: true,
      envName: EnvironmentName.Prod,
      storageBucketName: SHARED_STORAGE_BUCKET_PROD_V2,
    };
  }

  return {
    isPackToolsEnv: true,
    envName: EnvironmentName.Alpha,
    storageBucketName: SHARED_STORAGE_BUCKET_ALPHA_V2,
  };
};

export {
  ENVIRONMENT,
  getEnvironmentVariables,
  getEnv,
  EnvironmentName
};
